@import "assets/css/fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.filter-icon::after {
  position: absolute;
  top: 1.2rem;
  font-size: 0.7rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  font-weight: 300;
  color: #000000;
}

.filter-icon-tomorrow::after {
  content: "+1";
}

.filter-icon-7days::after {
  content: "7";
}

.filter-icon-14days::after {
  content: "14";
}