/* barlow-300 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/barlow/barlow-v12-latin-300.woff');
}
/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/barlow/barlow-v12-latin-regular.woff') format('woff');
}
/* barlow-500 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/barlow/barlow-v12-latin-500.woff') format('woff');
}

/* barlow-600 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/barlow/barlow-v12-latin-600.woff') format('woff');
}

/* barlow-700 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/barlow/barlow-v12-latin-700.woff');
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("./../../assets/fonts/font-awesome/fa-brands-400.woff2") format("woff2"),
  url("./../../assets/fonts/font-awesome/fa-brands-400.woff") format("woff");
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("./../../assets/fonts/font-awesome/fa-light-300.woff2") format("woff2"),
  url("./../../assets/fonts/font-awesome/fa-light-300.woff") format("woff");
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("./../../assets/fonts/font-awesome/fa-regular-400.woff2") format("woff2"),
  url("./../../assets/fonts/font-awesome/fa-regular-400.woff") format("woff");
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("./../../assets/fonts/font-awesome/fa-solid-900.woff2") format("woff2"),
  url("./../../assets/fonts/font-awesome/fa-solid-900.woff") format("woff");
}